import gql from "graphql-tag";
// PETANI
export const Q_LIST_FARMER = gql`
  query listPetani(
    $keyword: String
    $pages: Int
    $limit: Int
    $sp_id: [String!]
    $tss_id: [String!]
    $country_id: [Int!]
    $region_id: [Int!]
    $prov_id: [String!]
    $kab_id: [String!]
  ) {
    listPetani(
      listPetaniInput: {
        page: $pages
        limit: $limit
        search: {
          keyword: $keyword
          sp_id: $sp_id
          tss_id: $tss_id
          country_id: $country_id
          region_id: $region_id
          prov_id: $prov_id
          kab_id: $kab_id
        }
      }
    ) {
      page
      total
      totalPage
      petanis {
        id
        name
        sp {
          id
          name
        }
        tss {
          id
          name
        }
        kab_id
        kec {
          id
          kecamatan
        }
        kel {
          id
          kelurahan
        }
        luas
        alamat
        hp
        ucrops {
          id
          crop
        }
        ocrops {
          id
          crop
        }
      }
    }
  }
`;

// LAPORAN
export const Q_LIST_REPORT = gql`
  query listLaporan(
    $page: Int
    $limit: Int
    $keyword: String
    $from: [String!]
    $to: String
    $target_id: String
    $country_id: [Int!]
    $region_id: [Int!]
    $prov_id: [String!]
    $kab_id: [String!]
    $kec_id: [String!]
    $kel_id: [String!]
    $r1: [Int!]
    $r2: [Int!]
    $dis: [Int!]
    $petani_id: [String!]
    $produk_id: [String!]
    $year: String
    $month: String
    $start: DateTime
    $end: DateTime
  ) {
    listLaporan(
      listLaporanInput: {
        page: $page
        limit: $limit
        order: { sortBy: DESC, orderBy: CREATED_AT }
        search: {
          keyword: $keyword
          from: $from
          to: $to
          target_id: $target_id
          country_id: $country_id
          region_id: $region_id
          prov_id: $prov_id
          kab_id: $kab_id
          kec_id: $kec_id
          kel_id: $kel_id
          r1: $r1
          r2: $r2
          dis: $dis
          petani_id: $petani_id
          produk_id: $produk_id
          year: $year
          month: $month
          likR1: null
          likR2: null
          start_date: $start
          end_date: $end
        }
      }
    ) {
      page
      total
      totalPage
      laporans {
        id
        day_report
        produknames {
          id
          brand
        }
        price
        quantity
        value
        regions {
          region
        }
        retailers1 {
          id
          name
        }
        retailers2 {
          id
          name
        }
        distributors {
          id
          name
        }
        invoice
      }
    }
  }
`;

// LAPORAN HARIAN
export const Q_LIST_DAILY_REPORT = gql`
  query listDailyReport(
    $pages: Int
    $limit: Int
    $keyword: String
    $region_id: [ID!]
    $user_id: [ID!]
    $role_types: roleType
    $daily_types: dailyActivityType
    $start_date: DateTime
    $end_date: DateTime
  ) {
    listDailyReport(
      listDailyReportInput: {
        page: $pages
        limit: $limit
        search: {
          keyword: $keyword
          region_id: $region_id
          user_id: $user_id
          role_types: $role_types
          daily_types: $daily_types
          start_date: $start_date
          end_date: $end_date
        }
      }
    ) {
      page
      totalPage
      total
      dailys {
        id
        users {
          id
          name
          phone
        }
        role
        regions {
          id
          region
        }
        tanggal
        kegiatan
        distributor1 {
          id
          name
        }
        hd1
        distributor2 {
          id
          name
        }
        hd2
        retailer1 {
          id
          name
        }
        hr1
        retailer2 {
          id
          name
        }
        hr2
        retailer3 {
          id
          name
        }
        hr3
        petani1 {
          id
          name
        }
        hp1
        petani2 {
          id
          name
        }
        hp2
        nama_kebun
        hasil_kebun
        nama_marketing
        hasil_marketing
        nama_administrasi
        hasil_administrasi
        dokumentasi
      }
    }
  }
`;

// SET TARGET
export const Q_LIST_TARGET = gql`
  query listTarget(
    $page: Int
    $limit: Int
    $keyword: String
    $types: SalesTargetType
    $from: String
    $to: String
    $tahun: String
    $bulan: String
    $country_id: [ID!]
    $region_id: [ID!]
    $provinsi: [String!]
    $kabupatens: [String!]
    $kecamatans: [String!]
    $kelurahans: [String!]
    $produk_id: [String!]
    $sort: SortByType!
  ) {
    listTarget(
      listTargetInput: {
        page: $page
        limit: $limit
        search: {
          keyword: $keyword
          types: $types
          from: $from
          to: $to
          tahun: $tahun
          bulan: $bulan
          country_id: $country_id
          region_id: $region_id
          provinsi: $provinsi
          kabupatens: $kabupatens
          kecamatans: $kecamatans
          kelurahans: $kelurahans
          produk_id: $produk_id
        }
        order: { sortBy: $sort, orderBy: CREATED_AT }
      }
    ) {
      page
      total
      totalPage
      targets {
        id
        froms {
          id
          name
        }
        tos {
          id
          name
        }
        year
        month
        countrys {
          id
          country
        }
        regions {
          id
          region
        }
        provs {
          id
          province
        }
        kabs {
          id
          kabupaten
        }
        kecamatans {
          id
          kecamatan
        }
        kelurahans {
          id
          kelurahan
        }
        retailers1 {
          id
          name
        }
        retailers2 {
          id
          name
        }
        produknames {
          id
          brand
          price
        }
        price
        tipe_target
        target_quantity
        target_value
        isReported
        tipe_target
      }
    }
  }
`;
export const M_ADD_TARGET = gql`
  mutation addTarget(
    $from: String!
    $to: String!
    $produk: [ProdukTargetListInput!]!
  ) {
    createTarget(
      createTargetInput: { from: $from, to: $to, produkTarget: $produk }
    ) {
      id
    }
  }
`;
export const M_EDIT_TARGET = gql`
  mutation updateTarget(
    $id: ID!
    $from: String
    $to: String
    $month: String
    $year: String
    $price: Int
    $country: Int
    $region: Int
    $produk: String
    $qty: Int
    $value: String
    $tipe: SalesTargetType
  ) {
    updateTarget(
      updateTargetInput: {
        id: $id
        from: $from
        to: $to
        target_quantity: $qty
        target_value: $value
        month: $month
        year: $year
        price: $price
        country_id: $country
        region_id: $region
        produk_id: $produk
        tipe_target: $tipe
      }
    ) {
      id
      produk_id
    }
  }
`;

// List RM
export const Q_LIST_USER = gql`
  query allRM(
    $page: Int
    $limit: Int
    $keyword: String
    $role: roleType!
    $country_id: [Int!]
    $region_id: [Int!]
    $province_id: [String!]
    $kabupaten_id: [String!]
    $kecamatan_id: [String!]
    $kelurahan_id: [String!]
    $tss_id: [String!]
  ) {
    listUser(
      listUserInput: {
        page: $page
        limit: $limit
        search: {
          keyword: $keyword
          role: $role
          country_id: $country_id
          region_id: $region_id
          province_id: $province_id
          kabupaten_id: $kabupaten_id
          kecamatan_id: $kecamatan_id
          kelurahan_id: $kelurahan_id
          tss_id: $tss_id
        }
      }
    ) {
      users {
        id
        name
        role
        detail {
          country_id
          region_id
          province_id
          kabupaten_id
          kecamatan_id
          kelurahan_id
        }
      }
    }
  }
`;
export const Q_DETAIL_USER = gql`
  query detailUser($id: ID!) {
    detailUser(id: $id) {
      id
      detail {
        country_id
        region_id
      }
    }
  }
`;

// List Produk
export const Q_LIST_PRODUK = gql`
  query allProduk {
    listProduk(
      listProdukInput: { page: 1, limit: 999, search: { keyword: "" } }
    ) {
      produks {
        id
        brand
        price
        detail_produk {
          crops {
            crop
          }
        }
      }
    }
  }
`;

// List Laporan Collection
export const Q_LIST_TARGET_COLLECTION = gql`
  query listTargetCollection(
    $pages: Int
    $limit: Int
    $keyword: String
    $region_id: [ID!]
    $rm_id: ID
  ) {
    listTargetCollection(
      listTargetCollectionInput: {
        page: $pages
        limit: $limit
        search: { keyword: $keyword, region_id: $region_id, rm_id: $rm_id }
      }
    ) {
      page
      totalPage
      total
      tagetcols {
        id
        regional_manager {
          id
          name
          phone
        }
        regions {
          id
          region
        }
        distributors {
          id
          name
        }
        target
        isReported
        createdAt
      }
    }
  }
`;
export const M_ADD_TARGET_COLLECTION = gql`
  mutation createTargetCollection(
    $rm_id: String!
    $region_id: ID
    $distributor: ID
    $tagihan: String!
  ) {
    createTargetCollection(
      createTargetCollectionInput: {
        rm_id: $rm_id
        region_id: $region_id
        distributor: $distributor
        tagihan: $tagihan
      }
    ) {
      regional_manager {
        name
        phone
      }
      regions {
        region
      }
      distributors {
        name
      }
      target
    }
  }
`;
export const M_EDIT_TARGET_COLLECTION = gql`
  mutation updateTargetCollection(
    $id: ID!
    $rm_id: String
    $region_id: ID
    $distributor: ID
    $tagihan: String!
  ) {
    updateTargetCollection(
      updateTargetCollectionInput: {
        id: $id
        rm_id: $rm_id
        region_id: $region_id
        distributor: $distributor
        tagihan: $tagihan
      }
    ) {
      id
      regional_manager {
        id
        name
        phone
      }
      regions {
        id
        region
      }
      distributors {
        id
        name
      }
      target
    }
  }
`;
export const M_DEL_TARGET_COLLECTION = gql`
  mutation deleteTargetCollection($id: ID!) {
    deleteTargetCollection(id: $id) {
      id
    }
  }
`;

// List Data Distributor
export const Q_LIST_DISTRIBUTOR = gql`
  query listDistributor(
    $page: Int
    $limit: Int
    $keyword: String
    $country_id: [ID!]
    $region_id: [ID!]
    $province_id: [String!]
    $kabupaten_id: [String!]
    $kecamatan_id: [String!]
    $kelurahan_id: [String!]
  ) {
    listDistributor(
      listDistributorInput: {
        page: $page
        limit: $limit
        search: {
          keyword: $keyword
          country_id: $country_id
          region_id: $region_id
          province_id: $province_id
          kabupaten_id: $kabupaten_id
          kecamatan_id: $kecamatan_id
          kelurahan_id: $kelurahan_id
        }
      }
    ) {
      distributors {
        id
        name
        owner
        email
      }
    }
  }
`;
export const Q_DETAIL_DISTRIBUTOR = gql`
  query detailUser($id: ID!) {
    detailDistributor(id: $id) {
      region_id
    }
  }
`;

export const Q_LIST_REGION = gql`
  query listRegion {
    listRegion(
      listRegionInput: { page: 1, limit: 999, search: { keyword: "" } }
    ) {
      regions {
        id
        region
      }
    }
  }
`;

export const Q_LIST_COLLECTION = gql`
  query listCollection(
    $pages: Int
    $limit: Int
    $keyword: String
    $region_id: [ID!]
    $rm_id: [ID!]
  ) {
    listCollection(
      listCollectionInput: {
        page: $pages
        limit: $limit
        search: { keyword: $keyword, region_id: $region_id, rm_id: $rm_id }
        order: { orderBy: CREATED_AT, sortBy: DESC }
      }
    ) {
      page
      totalPage
      collections {
        id
        regional_manager {
          id
          name
          phone
        }
        regions {
          id
          region
        }
        distributors {
          id
          name
        }
        tanggal
        tagihan
        createdAt
      }
    }
  }
`;
